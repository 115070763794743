

































import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ProgressBarLinear extends Vue {
  public name = "ProgressBarLinear";

  @Prop()
  upcomingTheoryCourses!: any;

  public theoryCourses: any = [];

  public progressPercentage(students: any, slots: any) {
    const percentage = (100 * students) / slots;
    const rounded = Math.round(percentage);
    if (rounded > 100) {
      return 100;
    } else {
      return rounded;
    }
  }

  public setStyle(students: any, slots: any) {
    const percentage = (100 * students) / slots;
    const rounded = Math.round(percentage);
    if (rounded > 100) {
      return `color: #ff0000`;
    } else {
      return `color: #000000`;
    }
  }

  @Watch("upcomingTheoryCourses")
  public onUpcomingTheoryCoursesChange(courses: any): void {
    if (courses && courses.length > 0) {
      this.theoryCourses = [...courses];
      this.theoryCourses.shift();
    }
  }
}
