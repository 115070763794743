

































































import { Component, Watch } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import DashboardCalendar from "@/views/Dashboard/DashboardCalendar.vue";
import { namespace } from "vuex-class";
import UserService from "@/services/UserService";
import DashboardAppointments from "@/views/Dashboard/DashboardAppointments.vue";
import DashboardProfileSection from "@/views/Dashboard/DashboardProfileSection.vue";
import DashboardCurrentUserTodoList from "@/views/Dashboard/DashboardCurrentUserTodoList.vue";
import DashboardUpcoming from "@/views/Dashboard/DashboardUpcoming.vue";
import DashboardLivePayMessages from "@/views/Dashboard/DashboardLivePayMessages.vue";
import DashboardWarningMessages from "@/views/Dashboard/DashboardWarningMessages.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import DashboardFibuFixErrors from "@/views/Dashboard/DashboardFibuFixErrors.vue";
import { mixins } from "vue-class-component";
import FibuFixTransactionRequestMixin from "@/mixins/Request/FibuFixTransactionRequestMixin";

const CalendarModule = namespace("calendar");
const TheoryCourseModule = namespace("theory-course");
const PaymentsModule = namespace("payments");
const MandateModule = namespace("mandate");
const WarningsModule = namespace("warnings");

@Component({
  components: {
    DashboardFibuFixErrors,
    FscModal,
    DashboardLivePayMessages,
    DashboardWarningMessages,
    FscPageHeader,
    FscSimpleCard,
    DashboardCalendar,
    DashboardProfileSection,
    DashboardAppointments,
    DashboardCurrentUserTodoList,
    DashboardUpcoming,
  },
})
export default class Dashboard extends mixins(FibuFixTransactionRequestMixin) {
  public name = "Dashboard";

  @CalendarModule.Action("filterDashboardCalendar")
  public findAppointments: any;

  @CalendarModule.Getter("getDashBoardUserCalendar")
  public appointmentsByDate: any;

  @TheoryCourseModule.Action("findUpcomingTheoryCourses")
  public findAllUpcomingTheoryCourses: any;

  @TheoryCourseModule.Getter("getUpcomingTheoryCourses")
  public upcomingTheoryCourses: any;

  @PaymentsModule.Action("findAll")
  public findLivePayMessages: any;

  @PaymentsModule.Getter("getDataList")
  public livePayMessages: any;

  @MandateModule.Action("findOne")
  public findIsDrivingSchoolEnabled: any;

  @MandateModule.Getter("getDataItem")
  public isDrivingSchoolEnabled: any;

  @WarningsModule.Action("filter")
  public findWarningMessages: any;

  @WarningsModule.Getter("getDataList")
  public getWarningMessages: any;

  @WarningsModule.Getter("getTotal")
  public getWarningMessagesTotal: any;

  public todaysDate: any = null;
  public currentUser: any = null;
  public gridTemplate = "grid-template-columns: 3fr 1fr; grid-template-rows: 1fr; gap: 20px; overflow-y: auto";
  public tab = 0;
  public activeQuery: any = {};

  public mounted() {
    this.currentUser = UserService.getUser();
    const today = new Date().toISOString().slice(0, 10);
    this.todaysDate = today;
    const filter = {
      fromDate: today,
      toDate: today,
      instructorId: this.currentUser.id,
      vehicleIds: null,
      skipAppointmentsWithAvailableResources: false,
    };
    this.findAppointments(filter);
    this.findAllUpcomingTheoryCourses();
    this.findLivePayMessages({
      resource: "live-pay-message",
    });
    this.findIsDrivingSchoolEnabled({
      resource: "live-pay/driving_school_enabled",
      id: "current",
    });
    // this.onRequestQuery(this.activeQuery);

    // if (this.hasPermission) {
    //   this.getFibuFixErrors();
    // }
  }

  public get warningCount() {
    return this.getWarningMessagesTotal ? this.getWarningMessagesTotal : this.getWarningMessages?.length ? this.getWarningMessages.length : 0;
  }

  public get livePayMessagesCount() {
    return this.livePayMessages?.length || 0;
  }

  public onRequestQuery(query: any) {
    if (query) {
      this.activeQuery = query;
    } else {
      this.activeQuery = {
        offset: 0,
        limit: 25,
      };
    }
    this.findWarningMessages({
      resource: `/student-notifications`,
      filter: {
        ...this.activeQuery,
      },
    });
  }
  public onDeleteCompleted() {
    this.findWarningMessages({
      resource: `/student-notifications`,
      filter: {
        ...this.activeQuery,
      },
    });
  }

  @Watch("isDrivingSchoolEnabled", { deep: true, immediate: true })
  public onDrivingSchoolEnabled(isEnabled: any) {
    // if (isEnabled) {
    this.gridTemplate = "grid-template-columns: 2fr 2.05fr; grid-template-rows: 1fr; gap: 20px; overflow-y: auto";
    // } else {
    // this.gridTemplate = "grid-template-columns: 3fr 1fr; grid-template-rows: 1fr; gap: 20px; overflow-y: auto";
    // }
  }

  @Watch("activeQuery", { immediate: true, deep: true })
  public onQueryChange(newVal: any) {
    return newVal;
  }

  private get hasPermission() {
    return this.$can(this.MODULE_PAYMENT_WRITE);
  }
}
