
















import { Component, Vue, Watch, Prop } from "vue-property-decorator";
// @ts-ignore
import { VueEllipseProgress } from "vue-ellipse-progress";

@Component({
  components: { VueEllipseProgress },
})
export default class ProgressBarCircle extends Vue {
  public name = "ProgressBarCircle";

  @Prop()
  upcomingTheoryCourses!: any;

  public progress = 0;
  public studentsEnrolled: any = null;
  public placesCount: any = null;
  public fastLane = "";
  public course: any = null;

  public progressPercentage(students: any, slots: any) {
    const percentage = (100 * students) / slots;
    return Math.round(percentage);
  }

  @Watch("upcomingTheoryCourses", { deep: true, immediate: true })
  public onUpcomingTheoryCoursesChange(courses: any): void {
    if (courses && courses.length > 0) {
      const nextCourse = courses[0];
      this.course = courses[0];
      this.fastLane = nextCourse.name ? nextCourse.name : "";
      this.studentsEnrolled = nextCourse.studentCount ? nextCourse.studentCount : 0;
      this.placesCount = nextCourse.slots ? nextCourse.slots : 0;
      this.progress = this.progressPercentage(this.studentsEnrolled, this.placesCount);
    }
  }
}
