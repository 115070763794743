









































































import { Component, Prop, Watch } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import { namespace } from "vuex-class";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { IGroup } from "@/interfaces/IGroup";
import { mixins } from "vue-class-component";
import BaseMixin from "@/mixins/BaseMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import UserService from "@/services/UserService";

const TodoModule = namespace("todo");

@Component({
  components: { Table },
})
export default class DashboardCurrentUserTodoList extends mixins(BaseMixin) {
  name = "DashboardCurrentUserTodoList";

  @TodoModule.Getter("getDataList")
  public items: any;

  @TodoModule.Getter("getTotal")
  public totalRows: any;

  @TodoModule.Action("filter")
  public filterAction: any;

  @TodoModule.Getter("getIsLoading")
  public isLoadingTodo!: boolean;

  @TodoModule.Action("findStatuses")
  public findStatuses!: () => Promise<void>;

  @TodoModule.Getter("getStatuses")
  public statuses!: Array<IBasicNamedDTO>;

  @TodoModule.Action("findGroups")
  public findGroups!: () => Promise<void>;

  @TodoModule.Getter("getGroups")
  public groups!: Array<IGroup>;

  @TodoModule.Action("updateStatusOrGroup")
  public updateStatusOrGroupAction!: (options: ICrudOptions) => Promise<void>;

  @Prop()
  public user!: any;

  protected query: Record<any, any> | null = null;

  public fields = [
    {
      key: "createdOn",
      label: this.$t("general.created_on"),
      sortable: true,
    },
    {
      key: "dueDate",
      label: this.$t("general.due_date"),
      sortable: true,
    },
    {
      key: "status",
      label: this.$t("general.status"),
      sortable: true,
    },
    {
      key: "subject",
      label: this.$t("general.title"),
      sortable: true,
    },
    {
      key: "author.initials",
      label: this.$t("general.author"),
      sortable: true,
    },
    {
      key: "user.initials",
      label: this.$t("user.user"),
      sortable: true,
    },
    {
      key: "studentOrCostBearerName",
      label: this.$t("general.customer"),
      sortable: true,
    },
    {
      key: "priority.name",
      label: this.$t("general.priority"),
      sortable: true,
    },
    {
      key: "group",
      label: this.$t("general.group"),
      sortable: true,
    },
  ];

  public mounted() {
    setTimeout(() => {
      this.fetchData();
    }, 500);
  }
  public fetchData() {
    this.findStatuses();
    this.findGroups();
    // this.requestQuery({
    //   recipientId: this.user.id,
    // });
  }
  public get todos() {
    if (this.user.id && this.items) {
      return this.items;
    }
    return [];
  }
  private async onSelectGroup(group: IGroup, todoId: number): Promise<void> {
    await this.updateStatusOrGroup(group, todoId, "group");
  }

  public async onSelectStatus(status: IBasicNamedDTO, todoId: number): Promise<void> {
    await this.updateStatusOrGroup(status, todoId, "status");
  }

  public async updateStatusOrGroup(newData: Record<any, any>, todoId: number, type: "status" | "group"): Promise<void> {
    await this.updateStatusOrGroupAction({
      resource: `todos/${todoId}/${type}`,
      data: newData.id,
    });

    if (this.query) {
      this.requestQuery(this.query);
    }
  }

  public requestQuery(query: Record<any, any>): void {
    this.query = { ...query, recipientId: this.user.id };
    this.filterAction({
      resource: "todos",
      filter: { ...query, recipientId: this.user.id },
    });
  }
  @Watch("$route")
  public onRouteChange(newRoute: any) {
    if (newRoute.name.toLowerCase() === "dashboard") {
      this.$nextTick(() => {
        this.fetchData();
        this.requestQuery({
          recipientId: this.user.id,
        });
      });
    }
  }
}
