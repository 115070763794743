












import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import Table from "@/components/Table.vue";

@Component({
  components: { Table },
})
export default class DashboardFibuFixErrors extends Vue {
  public name = "DashboardFibuFixErrors";

  private fibufixErrorsFields = [
    { key: "studentId", label: "Student ID", sortable: true },
    {
      key: "method",
      label: "Method",
      sortable: true,
    },
    {
      key: "message",
      label: "Message",
      sortable: false,
      formatter: function (value: any, key: any, item: any) {
        try {
          return JSON.parse(value)?.message || "";
        } catch (e) {
          return value;
        }
      },
    },
    {
      key: "createdOn",
      label: "Created On",
      sortable: true,
      formatter: function (value: any, key: any, item: any) {
        return value ? moment(value).format("DD.MM.YYYY HH:mm") : "";
      },
    },
  ];

  @Prop({ required: true, type: Array, default: () => [] })
  public fibuFixErrors!: Array<any>;

  @Prop({ required: false, type: Boolean, default: () => false })
  public loading!: boolean;

  private onRowClicked(item: any) {
    if (item?.studentId) {
      this.$router.push({ name: "EducationEventList", params: { id: item.studentId } });
    }
  }
}
