
















































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import moment from "moment";

@Component({
  components: {
    FscSimpleCard,
    FscPageHeader,
  },
})
export default class DashboardAppointments extends Vue {
  public name = "DashboardAppointments";

  @Prop({ default: () => [] })
  public appointments!: any;

  @Prop()
  public today!: any;

  public filteredAppointments: any = [];

  public backgroundColorSet(color: any) {
    return `background-color: ${color}`;
  }

  public opacitySet(appointment: any) {
    let appointmentTime = moment(appointment);
    let current_time = moment();
    if (appointmentTime.isBefore(current_time)) {
      return `m-2 d-flex flex-column p-3 rounded-10 opc`;
    } else {
      return `m-2 d-flex flex-column p-3 rounded-10`;
    }
  }

  @Watch("appointments", { deep: true, immediate: true })
  public onAppointmentsChange(appointments: any): void {
    if (appointments && appointments.events) {
      let filtered = appointments.events.filter((event: any) => event.allDay !== true);
      this.filteredAppointments = filtered.sort((a: any, b: any) => Date.parse(a.start) - Date.parse(b.start));
    }
  }
}
